import React from "react";
import Layout from "../components/Layout";
import Paper from "@material-ui/core/Paper";
import passwordValidator from "password-validator";
import firebase from "firebase/app";
import { StripeProvider, Elements } from "react-stripe-elements";
import Checkout from "../components/Checkout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options as config } from "../styles/TypographySettings";
import { navigate } from "gatsby";

export default class Signup extends React.Component<any, any> {
  state = {
    email: "",
    pw: "",
    confPw: "",
    pwError: false,
    pwMsg: "Password",
    confMsg: "Confirm Password",
    miscErr: "",
    doc: null
  };

  validatePw() {
    const schema = new passwordValidator();
    schema
      .is()
      .min(8)
      .is()
      .max(100)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .symbols()
      .is()
      .not()
      .oneOf([this.state.email]);
    const pw = this.state.pw;
    const conf = this.state.confPw;
    const hasPw = pw.length > 0;
    const hasConf = conf.length > 0;
    const pwMsg = "Password";
    const confMsg = "Confirm Password";
    const errList: any = schema.validate(pw, { list: true });
    this.setState({ pwError: false, pwMsg, confMsg });

    if (hasPw && hasConf) {
      if (pw !== conf) {
        this.setState({
          pwError: true,
          pwMsg: "Invalid Password",
          confMsg: "Invalid Password"
        });
      }

      if (errList.length > 0) {
        this.setState({
          pwError: true,
          pwMsg: "Invalid Password",
          confMsg: "Invalid Password"
        });
      }
    } else {
      this.setState({ pwError: false, pwMsg, confMsg });
    }
  }

  updateField(event, field) {
    const value = event.target.value;
    this.setState({ [field]: value }, () => this.validatePw());
  }

  submit() {
    return () => {
      const email = this.state.email;
      const password = this.state.pw;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log({ errorCode, errorMessage });
        });
    };
  }

  renderStripe() {
    const getCopy = args => {
      const { context, name } = args;
      return context.featuredComponents.filter(
        item => item.title === name
      )[0].childContentfulAppComponentCopyRichTextNode.json;
    };
    const context = this.props.pageContext;
    const {
      metaDescription,
      searchIndex,
      childContentfulPageCopyRichTextNode,
      featuredComponents
    } = context;
    const SEO = { searchIndex, metaDescription };
    const _copy: any = context.childContentfulPageCopyRichTextNode.json;
    const copy = documentToReactComponents(_copy, config);
    const _tos: any = getCopy({ context, name: "Terms of Service" });
    const tos = documentToReactComponents(_tos, config);
    const content = (
      <Paper className="signup">
        <div className="signup__info">{copy}</div>
        <div className="signup__payment">
          <StripeProvider apiKey={process.env.STRIPE_KEY}>
            <Elements>
              <Checkout tos={tos} />
            </Elements>
          </StripeProvider>
        </div>
      </Paper>
    );
    const doc = (
      <Layout
        components={[{ columns: 12, content, className: "sign-up" }]}
        authRequired={false}
        seo={SEO}
      />
    );

    this.setState({ doc });
  }

  componentDidMount() {
    this.renderStripe();
    firebase.auth().onAuthStateChanged(async _user => {
      if (_user) {
        // User is signed in.
        navigate("/signin");
      }
    });
  }

  render() {
    return <div>{this.state.doc}</div>;
  }
}
